import { HTMLAttributeAnchorTarget } from 'react';

import { ButtonLink } from './ButtonLink';

interface LinkTileProps {
  text: string;
  linkText: string;
  href: React.ComponentProps<typeof ButtonLink>['href'];
  target?: HTMLAttributeAnchorTarget;
}

export default function LinkTile({
  text,
  linkText,
  href,
  target,
}: LinkTileProps) {
  return (
    <div className="flex flex-1 flex-col gap-1">
      <p className="font-medium">{text}</p>
      <ButtonLink
        className="w-fit self-center border-0 px-2 py-1"
        href={href}
        target={target}
        variant="text"
      >
        {linkText}
      </ButtonLink>
    </div>
  );
}
