'use client';

import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
} from '@apollo/experimental-nextjs-app-support';
import Cookies from 'js-cookie';
import { getSession } from 'next-auth/react';
import { type PropsWithChildren } from 'react';

import { getLink } from './link';

const getCookies = () => Promise.resolve(Cookies);

export const ApolloProvider = (props: PropsWithChildren) => {
  const makeClient = () =>
    new ApolloClient({
      cache: new InMemoryCache(),
      link: getLink({ getCookies, getSession }),
    });

  return <ApolloNextAppProvider {...props} makeClient={makeClient} />;
};
