import { getServerSession } from 'next-auth';
import { getSession as getClientSession } from 'next-auth/react';

import { authOptions } from './auth';
import { isServerSide } from './nextjs';

export function getSession() {
  if (isServerSide()) {
    return getServerSession(authOptions);
  } else {
    return getClientSession();
  }
}
