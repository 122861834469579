export enum SFListingStatus {
  ActiveForRent = 'Active For Rent',
  Active = 'Active',
  OnHold = 'On-Hold',
  EnteredIncomplete = 'Entered - Incomplete',
  EnteredPending = 'Entered - Pending',
  Resigned = 'Resigned',
  Inactive = 'Inactive',
  RemovedFromNetwork = 'Removed From Network',
  Building = 'Building',
}

// These values come from salesforce but there isn't a current need to rename so I'm leaving them as is
export enum ListingStage {
  DataCollection = 'Data Collection',
  Onboarding = 'Onboarding',
  BuildRequestSubmitted = 'Build Request Submitted',
  ListingBuildInitiated = 'Listing Build Initiated',
  BuildCompleted = 'Build Completed',
  QACompleted = 'QA Completed',
  EvolveListingActivated = 'Evolve Listing Activated',
  FullDistribution = 'Full Distribution',
  PropertySold = 'Property Sold',
}

export const NON_ONBOARDING_LISTING_STAGES = [
  ListingStage.EvolveListingActivated,
  ListingStage.FullDistribution,
  ListingStage.PropertySold,
];

export const allListingStatusesString: string =
  Object.values(SFListingStatus).join(',');

export enum ListingStatus {
  OnHold = 'On Hold',
  InProgress = 'In Progress',
  Active = 'Active',
  Incomplete = 'Incomplete',
  Resigned = 'Resigned',
}

export const getBucketedListingStatus = (status: SFListingStatus) => {
  switch (status) {
    case SFListingStatus.Active:
    case SFListingStatus.EnteredPending:
    case SFListingStatus.Building:
      return ListingStatus.InProgress;

    case SFListingStatus.ActiveForRent:
      return ListingStatus.Active;

    case SFListingStatus.EnteredIncomplete:
      return ListingStatus.Incomplete;

    case SFListingStatus.Inactive:
    case SFListingStatus.Resigned:
    case SFListingStatus.RemovedFromNetwork:
      return ListingStatus.Resigned;

    case SFListingStatus.OnHold:
      return ListingStatus.OnHold;
  }
};

export const defaultSortOrderMap: Record<ListingStatus, number> = {
  [ListingStatus.OnHold]: 5,
  [ListingStatus.InProgress]: 3,
  [ListingStatus.Active]: 1,
  [ListingStatus.Incomplete]: 4,
  [ListingStatus.Resigned]: 2,
} as const;

export const allListingsPageSortOrderMap: Record<ListingStatus, number> = {
  [ListingStatus.OnHold]: 2,
  [ListingStatus.InProgress]: 5,
  [ListingStatus.Active]: 3,
  [ListingStatus.Incomplete]: 1,
  [ListingStatus.Resigned]: 4,
} as const;

export default interface Listing {
  id: string;
  name: string;
  street: string;
  unit: string | null;
  streetAddress: string;
  city: string;
  state: string;
  stage: ListingStage;
  zipCode: string;
  country: string;
  propertyAddress: string;
  status: ListingStatus;
  averageRating: number;
  numberReviews: number;
  propertyName?: string;
  thumbnail: string;
}
