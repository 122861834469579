'use client';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect } from 'react';

import { refreshSession } from '../../hooks/auth';

const PAGE_BLOCKLIST = ['/listings/start'];

export const SFChat = () => {
  const { status, data: session } = useSession();
  const pathname = usePathname() || '';
  const sfChatEnabled = useFeatureIsOn('sf-chat');
  const isInvalidPage = PAGE_BLOCKLIST.includes(pathname);
  const { user } = session || {};
  const chatId = process.env.NEXT_PUBLIC_SF_CHAT_ID;
  const chatLabel = process.env.NEXT_PUBLIC_SF_CHAT_LABEL;
  const chatAppUrl = process.env.NEXT_PUBLIC_SF_CHAT_APP_URL;
  const scrt2URL = process.env.NEXT_PUBLIC_SF_CHAT_SCRT2URL;

  const initEmbeddedMessaging = useCallback(() => {
    if (
      !window.embeddedservice_bootstrap ||
      !user?.accessToken ||
      !chatId ||
      !chatLabel ||
      !chatAppUrl ||
      !scrt2URL
    )
      return;

    console.log('initializing SF chat');

    try {
      // When the API is ready, set the identity token
      window.addEventListener('onEmbeddedMessagingReady', () => {
        console.log('Messaging API is ready...setting token');

        window.embeddedservice_bootstrap?.userVerificationAPI.setIdentityToken({
          identityTokenType: 'JWT',
          identityToken: user.accessToken,
        });

        window.embeddedservice_bootstrap?.prechatAPI.setHiddenPrechatFields({
          UserId: user?.profile?.userId || '',
        });
      });

      window.addEventListener(
        'onEmbeddedMessagingIdentityTokenExpired',
        async () => {
          console.log('Identity token expired, refreshing token');
          const refreshedToken = await refreshSession({
            accessToken: user.accessToken,
          });
          window.embeddedservice_bootstrap?.userVerificationAPI.setIdentityToken(
            {
              identityTokenType: 'JWT',
              identityToken: refreshedToken,
            }
          );
        }
      );

      window.addEventListener('onEmbeddedMessagingWindowMaximized', () => {
        // Update the JWT so it doesn't get stale
        window.embeddedservice_bootstrap?.userVerificationAPI.setIdentityToken({
          identityTokenType: 'JWT',
          identityToken: user.accessToken,
        });
      });

      // For some reason, when logging in on mobile browsers this can sometimes
      // run twice and the first invocation seems to be too soon resulting in a
      // noisy error. Making it wait seems to fix it and removes the noisy error log
      setTimeout(() => {
        if (window.embeddedservice_bootstrap) {
          window.embeddedservice_bootstrap.settings.language = 'en_US';
          window.embeddedservice_bootstrap.settings.shouldMinimizeWindowOnNewTab =
            true;
          window.embeddedservice_bootstrap.init(chatId, chatLabel, chatAppUrl, {
            scrt2URL,
          });
        }
      }, 500);
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  }, [chatId, chatLabel, chatAppUrl, scrt2URL, user]);

  useEffect(() => {
    if (status === 'authenticated' && sfChatEnabled && !isInvalidPage) {
      if (document?.getElementById('sfChatSource')) return;

      const sourceScript = document.createElement('script');
      sourceScript.id = 'sfChatSource';
      sourceScript.src = process.env
        .NEXT_PUBLIC_SF_CHAT_SOURCE_SCRIPT as string;
      sourceScript.onload = () => initEmbeddedMessaging();

      document.head.appendChild(sourceScript);
    }
  }, [status, sfChatEnabled, initEmbeddedMessaging, isInvalidPage]);

  return null;
};
