import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import queryString from 'query-string';
import { useMemo } from 'react';

import { CACHE_TIMES } from 'constants/cache';
import Listing, {
  SFListingStatus,
  defaultSortOrderMap,
  getBucketedListingStatus,
} from 'types/Listing';

import { fetchWithAuth } from './auth';

export type ListingWithStatus = Omit<Listing, 'status'> & {
  status: SFListingStatus;
};

export interface ListingListOptions {
  limit?: number;
  offset?: number;
  sort?: keyof Listing;
  sortDirection?: 'asc' | 'desc';
  listingDataType: 'basic' | 'middle' | 'full';
  listingStatus?: SFListingStatus | SFListingStatus[];
  hideInactive?: boolean;
}

export function useListings(
  options: ListingListOptions,
  queryOptions: Omit<
    QueryObserverOptions<Listing[]>,
    'queryKey' | 'queryFn'
  > = {}
) {
  // If no listing status is provided, default to all used statuses
  if (!options.listingStatus) {
    options.listingStatus = Object.values(SFListingStatus);
  }

  const query = useMemo(
    () => queryString.stringify(options, { arrayFormat: 'comma' }),
    [options]
  );

  const { data: session } = useSession();

  const accessToken = session?.user?.accessToken;
  const userId = session?.user?.profile?.userId;

  return useQuery<Listing[]>({
    ...queryOptions,
    queryKey: ['listings', options],
    staleTime: CACHE_TIMES.ONE_DAY,
    enabled: Boolean(accessToken) && Boolean(userId),
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const { data } = await fetchWithAuth<ListingWithStatus[]>(
        `${process.env.NEXT_PUBLIC_EXPERIENCE_API_URL}/listings?${query}`,
        accessToken
      );

      let finalResponse = data?.map(
        (listing) =>
          ({
            ...listing,
            status: getBucketedListingStatus(listing.status),
          }) satisfies Listing
      );

      if (!options.sort && !options.sortDirection) {
        finalResponse = finalResponse?.sort(
          (a, b) =>
            defaultSortOrderMap[a.status] - defaultSortOrderMap[b.status] ||
            (a.propertyName || a.propertyAddress)?.localeCompare(
              b.propertyName || b.propertyAddress
            )
        );
      }

      return finalResponse ?? [];
    },
  });
}
