'use client';

import { TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePathname } from 'next/navigation';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { NuqsAdapter } from 'nuqs/adapters/next';

import { ApolloProvider } from 'clients/graphql/ApolloProvider';
import DatadogProvider from 'components/DatadogProvider';
import GrowthBookProvider from 'components/GrowthBookProvider';
import { ToastProvider } from 'components/Toast';
import { MobileContext } from 'components/contexts/MobileContext';
import { getQueryClient } from 'utils/queryClient';

interface ProvidersProps {
  session: Session | null;
  children: React.ReactNode;
}

// NEVER DO THIS:
// const queryClient = new QueryClient()
//
// Creating the queryClient at the file root level makes the cache shared
// between all requests and means _all_ data gets passed to _all_ users.
// Besides being bad for performance, this also leaks any sensitive data.

export default function Providers({
  session,
  children,
}: Readonly<ProvidersProps>) {
  const queryClient = getQueryClient();
  const pathname = usePathname();
  const isMobile = pathname?.includes('/mobile') ?? false;

  return (
    <NuqsAdapter>
      <TooltipProvider>
        <ToastProvider>
          <SessionProvider session={session}>
            <QueryClientProvider client={queryClient}>
              <ApolloProvider>
                <MobileContext value={isMobile}>
                  <GrowthBookProvider>
                    <DatadogProvider>
                      {process.env.NODE_ENV !== 'production' && (
                        <ReactQueryDevtools
                          buttonPosition="bottom-left"
                          client={queryClient}
                          initialIsOpen={false}
                        />
                      )}
                      {children}
                    </DatadogProvider>
                  </GrowthBookProvider>
                </MobileContext>
              </ApolloProvider>
            </QueryClientProvider>
          </SessionProvider>
        </ToastProvider>
      </TooltipProvider>
    </NuqsAdapter>
  );
}
