// https://github.com/testing-library/dom-testing-library/pull/85

import {
  isValidElement,
  Children,
  type ReactNode,
  type ReactElement,
  type JSXElementConstructor,
} from 'react';

/**
 * Sets the underlying value of an element bypassing React's change tracking.
 * This allows a custom change event to then be fired without being ignored by React.
 */
export function setNativeValue(
  element: Element,
  value: string | number | readonly string[] | undefined
): void {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value'
  )?.set;

  if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}

export function hasChild(
  children: ReactNode | ReactNode[],
  name: string
): boolean {
  return Children.toArray(children).some(
    (el) =>
      ((el as ReactElement).type as JSXElementConstructor<unknown>)?.name ===
      name
  );
}

export function pluckChild(
  children: ReactNode | ReactNode[],
  name: string
): ReactNode | undefined {
  return Children.toArray(children).find(
    (el): el is ReactElement =>
      isValidElement(el) &&
      (el.type as JSXElementConstructor<unknown>)?.name === name
  );
}

export function excludeChild(
  children: ReactNode | ReactNode[],
  name: string
): ReactNode | undefined {
  return Children.toArray(children).find(
    (el) =>
      name !==
      ((el as ReactElement).type as JSXElementConstructor<unknown>)?.name
  );
}
