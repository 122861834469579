'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useRef, useEffect } from 'react';

import { hubspot } from 'utils/hubspot';

interface Props {
  email: string | undefined;
}

/**
 * **HubSpot Tracking Code**
 *
 * The HubSpot tracking code is unique to each HubSpot account and allows HubSpot to monitor your website traffic.
 *
 * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code
 */
export const HubSpot = ({ email }: Props) => {
  const pathname = usePathname() as `/${string}`;
  const query = useSearchParams()?.toString();
  const href = `${pathname}${query.length ? `?${query}` : ''}` as const;
  const prevHrefRef = useRef<string | undefined>(href);

  // Identify user
  useEffect(() => {
    if (email) {
      hubspot.identify({ email });
    }
  }, [email]);

  // Track page views
  useEffect(() => {
    hubspot.setPath(href);

    if (prevHrefRef.current !== href) {
      prevHrefRef.current = href;
      hubspot.trackPageView();
    }
  }, [href]);

  return (
    <Script
      async
      defer
      id="hs-script-loader"
      src="//js.hs-scripts.com/121795.js"
      type="text/javascript"
    />
  );
};
