import { type HTMLAttributes } from 'react';

import { cn } from 'utils/tailwind';

export type DialogContentProps = HTMLAttributes<HTMLDivElement>;

export const DialogContent = ({ className, ...props }: DialogContentProps) => (
  <div
    {...props}
    className={cn(
      'w-full flex-1 overflow-hidden text-ellipsis px-4 md:px-6',
      'group-data-[scrollable]/dialog:overflow-y-auto group-data-[scrollable]/dialog:border-y group-data-[scrollable]/dialog:border-y-cool-gray group-data-[fullscreen]/dialog:px-6 group-data-[scrollable]/dialog:pt-4 md:group-data-[scrollable]/dialog:py-6',
      className
    )}
  />
);
