import {
  DialogContentProps,
  DialogProps as RootProps,
} from '@radix-ui/react-dialog';
import * as RadixDialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { ReactNode } from 'react';

import { IconButton } from 'components/Buttons/IconButton';
import { CloseIcon } from 'components/Icons';
import { pluckChild } from 'utils/react';
import { cn } from 'utils/tailwind';

import { DialogCover } from './DialogCover';
import { DialogFooter } from './DialogFooter';

export type DialogProps = RootProps & {
  trigger?: ReactNode;
  scrollable?: boolean;
  fullscreen?: boolean;
  showCloseButton?: boolean;
  contentClassName?: string;
  position?: 'top' | 'center';
  DialogContentProps?: DialogContentProps;
};

export const Dialog = ({
  trigger,
  children,
  scrollable,
  fullscreen,
  showCloseButton = true,
  contentClassName,
  DialogContentProps,
  position = 'center',
  ...props
}: DialogProps) => {
  // Scrollable dialogs cannot have cover images
  const cover = scrollable ? undefined : pluckChild(children, DialogCover.name);
  const footer = pluckChild(children, DialogFooter.name);
  const hasCover = Boolean(cover);

  return (
    <RadixDialog.Root {...props}>
      {trigger && <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>}
      <RadixDialog.Portal>
        <RadixDialog.Overlay
          className={cn('fixed inset-0 z-50 flex justify-center bg-black/30', {
            'items-center': position === 'center',
            'items-start': position === 'top',
          })}
        >
          <RadixDialog.Content
            aria-describedby={DialogContentProps?.['aria-describedby']}
            // Coalescing to undefined removes the attribute instead of setting data-attr="false"
            data-footer={footer || undefined}
            data-fullscreen={fullscreen || undefined}
            data-has-close-button={showCloseButton || undefined}
            data-has-cover={hasCover || undefined}
            data-scrollable={scrollable || undefined}
            className={cn(
              'group/dialog z-50 w-full',
              'flex flex-col items-stretch bg-white pt-12 text-typography dark:bg-island-dark dark:text-white',
              'data-[has-cover]:pt-0',
              {
                'pb-5': !scrollable && !footer,
                'h-full': fullscreen,
                'max-h-[70%] w-80 sm:w-96 md:w-[40rem] rounded drop-shadow dark:drop-shadow-dark':
                  !fullscreen,
              },
              contentClassName
            )}
            {...DialogContentProps}
          >
            {/* Visually hidden title for accessibility and to avoid errors where title is not provided */}
            <VisuallyHidden.Root>
              <RadixDialog.Title>Hidden Title</RadixDialog.Title>
            </VisuallyHidden.Root>
            {children}
            {showCloseButton && (
              <RadixDialog.Close asChild>
                <IconButton
                  className="absolute right-2 top-2 -mt-0.5 group-data-[scrollable]/dialog:md:mt-2"
                  icon={<CloseIcon />}
                  label="Close"
                  variant={hasCover ? 'outline' : 'subtle'}
                />
              </RadixDialog.Close>
            )}
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
