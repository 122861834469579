'use client';

import { Title, Close } from '@radix-ui/react-dialog';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { use, useEffect, useRef, useState } from 'react';
import {
  useEventCallback,
  useLocalStorage,
  useMediaQuery,
  useTimeout,
} from 'usehooks-ts';

import { Button } from 'components/Buttons';
import { Dialog, DialogFooter } from 'components/Dialog';
import { MobileContext } from 'components/contexts/MobileContext';
import { useBreakpoints } from 'hooks/breakpoints';
import { useFeatureIsOn } from 'hooks/useFeatureIsOn';
import { trackEvent } from 'utils/segment';
import { cn } from 'utils/tailwind';

import { useListings } from '../../hooks/useListings';
import { SFListingStatus } from '../../types/Listing';

const PAGE_BLOCKLIST = ['/login', '/forgot-password', '/performance', '/start'];
const MOUNT_DELAY = 2000;
const VIDEO_DELAY = 3000;
const VIDEO_PLAY_COUNT = 4;
const VIDEO_URL_DESKTOP =
  'https://res.cloudinary.com/evolve-vacation-rental-network/video/upload/v1732138622/web/cmr-cms-intro-desktop.mp4';
const VIDEO_URL_MOBILE =
  'https://res.cloudinary.com/evolve-vacation-rental-network/video/upload/v1732138623/web/cmr-cms-intro-mobile.mp4';

const playSafe = async (element: HTMLVideoElement) => {
  try {
    await element.play();
  } catch (error) {
    console.error('Error playing video', error);
  }
};

/**
 * Play a breakpoint-based video a limited number of times after a delay since the element became visible.
 *
 * @param isVisible - Whether the video is visible
 * @returns `<video />` props
 */
const useControlledVideo = (isVisible: boolean) => {
  const { isMd } = useBreakpoints();
  const src = isMd ? VIDEO_URL_DESKTOP : VIDEO_URL_MOBILE;

  const ref = useRef<HTMLVideoElement>(null);

  // Play the video a limited number of times
  useEffect(() => {
    let element = ref.current;
    const timer = setTimeout(() => {
      element = ref.current;
      let playCount = 0;

      async function handleEnded(this: HTMLVideoElement) {
        playCount += 1;
        if (playCount < VIDEO_PLAY_COUNT) {
          await playSafe(this);
        }
      }

      ref.current?.addEventListener('ended', handleEnded);
    }, 1000);

    return () => {
      clearTimeout(timer);
      element?.removeEventListener('ended', () => void 0);
    };
  }, [isVisible, src]);

  const prefersReducedMotion = useMediaQuery(
    '(prefers-reduced-motion: reduce)'
  );

  const shouldPlay = isVisible && !prefersReducedMotion;

  // Play the video after a delay
  useEffect(() => {
    const timeoutId = shouldPlay
      ? setTimeout(() => {
          if (ref.current) {
            void playSafe(ref.current);
          }
        }, VIDEO_DELAY)
      : undefined;

    return () => clearTimeout(timeoutId);
  }, [shouldPlay, src]);

  /** `<video />` props */
  return {
    src,
    ref,
    autoPlay: false,
    muted: true,
    playsInline: true,
    controls: false,
    preload: 'auto',
  };
};

export const WelcomeCMSCMRModal = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { isMd } = useBreakpoints();
  const isMobile = use(MobileContext);
  const isCustomStaysEnabled = useFeatureIsOn('custom-stays');

  const hideBlockingUI = searchParams.get('hideBlockingUI') === 'true';
  const isInvalidPage = PAGE_BLOCKLIST.includes(pathname);

  const { data: session } = useSession();

  const [showWelcomeModal, setShowWelcomeModal] = useLocalStorage(
    `showWelcomeCMS_CMRModal-${session?.user.id ?? ''}`,
    !hideBlockingUI && !isInvalidPage && isCustomStaysEnabled
  );

  // Set "isMounted" state to true after a 2 second delay
  const [isMounted, setIsMounted] = useState(false);
  useTimeout(() => setIsMounted(true), MOUNT_DELAY);

  const isOpen = showWelcomeModal && isMounted && !isMobile;

  const videoProps = useControlledVideo(isOpen);

  const handleOpenChange = useEventCallback((newValue: boolean) => {
    setShowWelcomeModal(newValue);
    if (!newValue) {
      void trackEvent('New Dialog Dismissed', {
        feature: 'Custom Min Stay/Rate',
      });
    }
  });

  const { data: listings } = useListings({
    listingStatus: Object.values(SFListingStatus),
    listingDataType: 'full',
  });

  const handleCtaClick = useEventCallback(() => {
    trackEvent('New Dialog CTA Clicked', {
      feature: 'Custom Min Stay/Rate',
    });

    setShowWelcomeModal(false);

    if (listings?.length === 1) {
      router.push(`/listings/${listings[0].id}?tab=rates-policies-rules`);
      return;
    }

    router.push(`/listings`);
  });

  useEffect(() => {
    if (isOpen) {
      void trackEvent('New Dialog', { feature: 'Custom Min Stay/Rate' });
    }
  }, [isOpen]);

  return (
    <Dialog
      modal
      DialogContentProps={{ onPointerDownOutside: (e) => e.preventDefault() }}
      contentClassName="mx-6 max-h-[calc(100dvh-48px)] !w-full !max-w-[535px] overflow-y-auto rounded-lg pb-0 pt-8 md:mx-8 md:pt-10 lg:pt-12 flex flex-col"
      onOpenChange={handleOpenChange}
      open={isOpen}
      showCloseButton={isMd}
    >
      <Title className="px-3 pb-2 text-center font-heading text-[24px] font-semibold leading-8 md:px-10">
        Say hello to Custom Minimum Rates and Stays
      </Title>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <p className="mb-5 px-3 text-center text-[18px] leading-7 md:mb-6 md:px-10">
          Take control of your strategy by temporarily adjusting your
          ground-floor pricing or length of stay in the Listing Rates, policies,
          & fees tab.
        </p>
        <div
          className={cn(
            'flex aspect-[1.9/1] h-full flex-1 items-center justify-center bg-island-50',
            isMd ? 'aspect-[1.9/1]' : 'aspect-[3/2]'
          )}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video {...videoProps} className="pointer-events-none size-full" />
        </div>
      </div>
      <DialogFooter className="px-6 pb-7 pt-5 md:px-10 md:pb-9 lg:pb-10">
        <Close asChild>
          <Button className="order-2 md:order-1" variant="subtle">
            Dismiss
          </Button>
        </Close>
        <Button
          className="order-1 md:order-2"
          onClick={handleCtaClick}
          type="button"
          variant="solid"
        >
          Check it out
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
