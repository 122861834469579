import { datadogRum } from '@datadog/browser-rum';
import { SignOutParams, signOut as _signOut } from 'next-auth/react';

export default async function signOut(options?: SignOutParams<boolean>) {
  datadogRum.stopSession();
  datadogRum.clearUser();

  // Clear the SF chat session.
  if (window.embeddedservice_bootstrap?.userVerificationAPI) {
    try {
      await window.embeddedservice_bootstrap.userVerificationAPI.clearSession();
    } catch (error) {
      console.error('Failed to clear chat session', error);
    }
  }

  await _signOut(options);
}
