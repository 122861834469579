import { useFeatureIsOn as gbUseFeatureIsOn } from '@growthbook/growthbook-react';
import { use } from 'react';

import { MobileContext } from 'components/contexts/MobileContext';
import { type AppFeatures } from 'utils/growthbook';

export function useFeatureIsOn(feature: keyof AppFeatures) {
  const isMobile = use(MobileContext);

  return gbUseFeatureIsOn(isMobile ? `${feature}-mobile` : feature);
}
