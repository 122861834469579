import { type ReactNode } from 'react';

import {
  BaseButton,
  BaseButtonProps,
} from 'components/Buttons/shared/BaseButton';
import { cn } from 'utils/tailwind';

export type IconButtonProps = Omit<BaseButtonProps, 'children'> & {
  icon: ReactNode;
  /**
   * The label to present to screen readers
   */
  label: string;
  /**
   * Size of the button
   * @default large
   */
  size?: 'large' | 'small';
};

export const IconButton = ({
  ref,
  icon,
  label,
  className,
  size = 'large',
  ...buttonProps
}: IconButtonProps) => (
  <BaseButton
    ref={ref}
    title={label}
    className={cn(
      'inline-flex flex-none',
      {
        'size-11': size === 'large',
        'size-8 text-xs': size === 'small',
      },
      className
    )}
    {...buttonProps}
  >
    {icon}
  </BaseButton>
);
