import { type HTMLAttributes } from 'react';

import { cn } from 'utils/tailwind';

export type DialogFooterProps = HTMLAttributes<HTMLDivElement>;

export const DialogFooter = ({ className, ...props }: DialogFooterProps) => (
  <div
    {...props}
    // Show different paddings if scrollable or fullscreen
    className={cn(
      'flex flex-col gap-4 px-4 pb-9 pt-4 group-data-[fullscreen]/dialog:px-6 group-data-[scrollable]/dialog:py-5 md:flex-row md:justify-end md:pt-6',
      className
    )}
  />
);
