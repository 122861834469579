import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type Ref,
} from 'react';

import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

export type ButtonVariant =
  | 'solid'
  | 'outline'
  | 'outline-new'
  | 'subtle'
  | 'text';

export type BaseButtonProps = {
  ref?: Ref<HTMLButtonElement | HTMLAnchorElement>;
  /**
   * The type of button to render.
   * @default outline
   */
  variant?: ButtonVariant;
  /**
   * An href to render a button as a link.
   * Providing a value for this prop will automatically change the underlying component from a `button` to an `a`.
   * @default undefined
   */
  href?: string;
} & BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export const BaseButton = ({
  ref,
  variant,
  invertTheme,
  className,
  ...props
}: BaseButtonProps) => {
  const Component = props.href ? 'a' : 'button';

  return (
    <Component
      ref={ref as Ref<never>}
      className={cn(
        'flex appearance-none items-center justify-center border-2 border-solid font-heading text-sm font-bold leading-5 outline-none transition-colors',
        'focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-2',
        'disabled:pointer-events-none data-loading:pointer-events-none',
        {
          'theme-invert': invertTheme,
          'rounded-full bg-cta text-white hover:bg-mountain-600 active:bg-mountain-400 border-transparent disabled:bg-disabled-light disabled:text-white data-loading:bg-mountain-400':
            variant === 'solid',
          'rounded-full bg-transparent border-cta text-cta hover:bg-mountain-100 hover:text-mountain-700 hover:border-mountain-700 focus-visible:bg-transparent focus-visible:border-cta focus-visible:text-cta active:bg-transparent active:border-mountain-400 active:text-mountain-400 data-loading:bg-transparent data-loading:border-mountain-400 data-loading:text-mountain-400 disabled:bg-transparent disabled:border-disabled-light disabled:text-disabled-light dark:bg-island-dark dark:border-mountain-100 dark:text-mountain-100 dark:hover:bg-island-700 dark:active:bg-island-700 dark:active:border-white dark:active:text-white dark:disabled:text-disabled-dark dark:disabled:border-disabled-dark dark:data-loading:bg-island-700':
            variant === 'outline',
          'rounded-full bg-transparent border-island-700 text-island-700 hover:border-island-600 hover:bg-island-100 hover:text-island-700 active:border-island-500 active:bg-island-100 active:text-island-700 disabled:bg-transparent disabled:border-disabled-light disabled:text-disabled-light data-loading:bg-island-100':
            variant === 'outline-new',
          'rounded-full bg-transparent border-transparent text-cta focus-visible:ring-offset-0 hover:bg-mountain-100 hover:text-mountain-700 active:bg-transparent active:text-mountain-400 data-loading:bg-mountain-100 disabled:bg-transparent disabled:text-disabled-light dark:text-mountain-100 dark:hover:bg-island-700 dark:active:bg-island-700 dark:data-loading:bg-island-700 dark:active:text-white dark:disabled:text-disabled-dark':
            variant === 'subtle',
          'border-transparent rounded-none text-cta hover:text-mountain-600 active:text-mountain-400 disabled:text-disabled-light dark:text-mountain-100 dark:hover:text-mountain-400 dark:active:text-white dark:disabled:text-disabled-dark':
            variant === 'text',
        },
        className
      )}
      {...props}
    />
  );
};
