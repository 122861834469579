'use client';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import Image from 'next/image';

import { Heading } from 'components/Heading';
import { useBreakpoints } from 'hooks/breakpoints';
import { useSegmentPageView } from 'hooks/useSegmentPageView';

import LinkTile from './LinkTile';
import { Logo } from './Logo';
import meditation from '../public/meditation.svg';

export default function MaintenanceModeBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  const maintenanceMode = useFeatureIsOn('maintenance-mode');
  useSegmentPageView('Maintenance Page', {
    enabled: maintenanceMode,
    runOnce: true,
  });
  const { isMd, isLg, isXl } = useBreakpoints();
  if (!maintenanceMode) {
    return <>{children}</>;
  } else {
    return (
      <>
        <div className="px-6 pt-11 md:px-12 md:pt-8 xl:px-20">
          <Logo className="h-4 lg:h-6" />
        </div>
        <div className="my-auto flex flex-col gap-8 px-4 pb-20 pt-7 text-center md:gap-10 md:px-12 md:pt-20 lg:gap-16 lg:pt-16 xl:gap-20 xl:px-20 xl:pt-20">
          <div className="flex flex-col gap-4 md:gap-6">
            <Image
              alt=""
              className="self-center"
              src={meditation}
              width={isXl ? 213 : isLg ? 192 : isMd ? 170 : 140}
            />{' '}
            <div className="flex flex-col gap-2 text-island-dark md:gap-3 lg:gap-4">
              <Heading level={2}>We&apos;re down for maintenance.</Heading>
              <Heading className="leading-7" level={4}>
                Breathe deep and exhale knowing we&apos;re working on a better
                experience.
              </Heading>
            </div>
          </div>
          <div className="flex flex-col gap-4 self-center px-4 lg:flex-row lg:gap-16 xl:px-10">
            <LinkTile
              href="tel:977-818-1014"
              linkText="(977) 818-1014"
              text="For immediate help, call Evolve 24/7"
            />
            <LinkTile
              href="https://evolve.com/contact?utm_campaign=20357982128&utm_content=155769171587&utm_medium=cpc&utm_source=google&utm_term=evolve%20customer%20service"
              linkText="For Homeowners & Partners"
              target="_blank"
              text="Visit our Help Center for FAQs"
            />
            <LinkTile
              href="https://evolve.com/owner?utm_campaign=20357982128&utm_content=155769171587&utm_medium=cpc&utm_source=google&utm_term=evolve%20customer%20service"
              linkText="Learn more"
              target="_blank"
              text="Manage and Grow with Evolve"
            />
          </div>
        </div>
      </>
    );
  }
}
