import { isServerSide } from 'utils/nextjs';

type HubSpotQueue = NonNullable<Window['_hsq']>;
type HubSpotCall = HubSpotQueue[number];
type HubSpotMethod = HubSpotCall[0];
type HubSpotArgs<Method extends HubSpotMethod> =
  Extract<HubSpotCall, [Method, ...unknown[]]> extends [Method, ...infer Args]
    ? Args
    : never;

/**
 * Creates a type-safe caller for a HubSpot tracking code method
 */
const getCaller =
  <TMethod extends HubSpotMethod>(method: TMethod) =>
  (...args: HubSpotArgs<TMethod>) => {
    if (isServerSide()) {
      return;
    }
    window._hsq ??= [];
    window._hsq.push([method, ...args] as unknown as HubSpotCall);
  };

/**
 * HubSpot Tracking Code API
 *
 * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#tracking-code-api-overview
 */
export const hubspot = {
  /**
   * Get cross-domain linking parameters
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#get-cross-domain-linking-parameters
   */
  addIdentityListener: getCaller('addIdentityListener'),

  /**
   * Identify a visitor
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#identify-a-visitor
   */
  identify: getCaller('identify'),

  /**
   * Reapply analytics event handlers
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#reapply-analytics-event-handlers
   */
  refreshPageHandlers: getCaller('refreshPageHandlers'),

  /**
   * Set the current page path, including query parameters
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#set-page-path
   */
  setPath: getCaller('setPath'),

  /**
   * Track a custom behavioral event
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#trackcustombehavioralevent
   */
  trackCustomBehavioralEvent: getCaller('trackCustomBehavioralEvent'),

  /**
   * Track an event
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#events-js-api
   */
  trackEvent: getCaller('trackEvent'),

  /**
   * Track a page view
   *
   * @link https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/tracking-code#track-page-view
   */
  trackPageView: getCaller('trackPageView'),
} as const;
