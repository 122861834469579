import { QueryCache, QueryClient, isServer } from '@tanstack/react-query';

import { CACHE_TIMES } from 'constants/cache';
import { isSalesforceUser } from 'utils/auth';
import { getSession } from 'utils/getSession';

import { isClientSide } from './nextjs';
import signOut from './signOut';

let browserQueryClient: QueryClient | undefined = undefined;

let signoutPromise: Promise<void> | undefined = undefined;

async function trySignOut() {
  if (!isSalesforceUser(await getSession())) {
    console.info('Signing user out for unauthorized token');

    await signOut();

    // Cooldown period for signout calls. Currently, if 5 concurrent network calls result in irrecoverable 401s,
    // the QueryCache onError will trigger 5 times. This attempts to deduplicate those into a single signout call.
    setTimeout(() => {
      signoutPromise = undefined;
    }, 10000);
  }
}

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: CACHE_TIMES.ONE_HOUR,
        // I would set to true but performance is not where it needs to be for this kind of functionality to work well
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
    queryCache: new QueryCache({
      onError: async (error) => {
        if (isClientSide()) {
          // @ts-expect-error Javascript errors can be anything
          if (error?.response?.status === 401) {
            if (signoutPromise === undefined) {
              signoutPromise = trySignOut();
            }

            await signoutPromise;
          }
        }
      },
    }),
  });
}

// Taken from https://tanstack.com/query/latest/docs/framework/react/guides/suspense#suspense-on-the-server-with-streaming
export function getQueryClient() {
  // Server: always make a new query client
  if (isServer) return makeQueryClient();

  // Browser: make a new query client if we don't already have one
  // This is very important, so we don't re-make a new client if React
  // suspends during the initial render. This may not be needed if we
  // have a suspense boundary BELOW the creation of the query client
  return (browserQueryClient ??= makeQueryClient());
}
