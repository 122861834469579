'use client';

import { Item, ToggleGroupItemProps } from '@radix-ui/react-toggle-group';
import { useContext } from 'react';

import { cn } from 'utils/tailwind';

import { ButtonGroupContext } from './ButtonGroup';

import type { ButtonHTMLAttributes } from 'react';

export type ButtonSegmentProps = ToggleGroupItemProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonSegment = ({
  children,
  className,
  ...props
}: ButtonSegmentProps) => {
  const { activeIcon, noIcon } = useContext(ButtonGroupContext);
  return (
    <Item
      {...props}
      className={cn(
        'group flex h-11 flex-row content-center items-center gap-2 border-2 px-7 font-heading text-sm font-semibold',
        'z-10 -ml-0.5 appearance-none outline-none first:ml-0 first:rounded-l-full last:rounded-r-full hover:z-20 focus:z-20 disabled:z-0',
        'disabled:pointer-events-none',
        'focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-2',
        'border-mountain bg-white text-mountain dark:border-mountain-300 dark:bg-island-dark dark:text-white',
        'hover:bg-mountain-100 hover:text-mountain-700 active:bg-mountain-100 dark:hover:bg-island-700 dark:active:bg-island-600',
        'disabled:border-disabled-light disabled:text-disabled-light disabled:dark:border-disabled-dark disabled:dark:text-disabled-dark',
        'radix-state-on:bg-mountain-200 radix-state-on:text-mountain-700 radix-state-on:hover:bg-mountain-300 radix-state-on:active:bg-mountain-400 radix-state-on:disabled:border-disabled radix-state-on:disabled:bg-disabled-light radix-state-on:disabled:text-disabled',
        'radix-state-on:dark:bg-island-700 radix-state-on:hover:dark:bg-island-600 radix-state-on:active:dark:bg-island radix-state-on:disabled:dark:border-disabled-dark radix-state-on:disabled:dark:bg-disabled radix-state-on:disabled:dark:text-disabled-dark',
        !noIcon && 'radix-state-on:pl-4 radix-state-on:pr-[1.125rem] ',
        className
      )}
    >
      {!noIcon && (
        <span className="hidden group-radix-state-on:inline">{activeIcon}</span>
      )}
      <span>{children}</span>
    </Item>
  );
};
