'use client';

import {
  FeaturesReady,
  GrowthBook,
  GrowthBookProvider as GBProvider,
} from '@growthbook/growthbook-react';
import { usePathname, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { use, useEffect, useState } from 'react';

import { getIsImpersonating } from 'utils/auth';

import { MobileContext } from './contexts/MobileContext';

const GROWTHBOOK_API_HOST = 'https://cdn.growthbook.io';

const GrowthBookProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isMobile = use(MobileContext);
  const { data: sessionData, status: sessionStatus } = useSession();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const fullPath = `${pathname}?${searchParams}`;
  const [growthbookReady, setGrowthbookReady] = useState(false);
  const [loadedUser, setLoadedUser] = useState(false);
  const [growthbook] = useState(() => {
    const clientKey = isMobile
      ? process.env.NEXT_PUBLIC_GROWTHBOOK_MOBILE_CLIENT_KEY
      : process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY;

    return new GrowthBook({
      apiHost: GROWTHBOOK_API_HOST,
      clientKey,
      enableDevMode: true,
      subscribeToChanges: true,
    });
  });

  useEffect(() => {
    if (growthbookReady) {
      growthbook.updateAttributes({
        url: fullPath,
      });
    }
  }, [fullPath, growthbook, growthbookReady]);

  useEffect(() => {
    const load = async () => {
      await growthbook.loadFeatures();
      setGrowthbookReady(true);
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set Growthbook attributes with user session data
  // required for feature flags to work
  useEffect(() => {
    if (sessionStatus !== 'loading' && growthbookReady) {
      const hasActiveListing = sessionData?.user?.profile?.hasActiveListing;
      const isImpersonator = getIsImpersonating(sessionData);
      const isOnboarding = sessionData?.user?.profile?.isOnboarding;
      const managementTier = sessionData?.user?.profile?.managementTier;
      const userId = sessionData?.user?.profile?.userId;
      const userEmail = sessionData?.user?.profile?.email;
      const username = sessionData?.user?.profile?.username;

      try {
        if (userId && userEmail) {
          growthbook.updateAttributes({
            email: userEmail,
            hasActiveListing,
            id: userId,
            isImpersonator,
            isOnboarding,
            managementPlan: managementTier,
            username,
          });
        }

        setLoadedUser(true);
      } catch (error) {
        console.error('Error setting GrowthBook attributes:', error);
      }
    }
  }, [sessionData, sessionStatus, growthbook, growthbookReady]);

  return (
    <GBProvider growthbook={growthbook}>
      <FeaturesReady fallback={<div />} timeout={8000}>
        {loadedUser && children}
      </FeaturesReady>
    </GBProvider>
  );
};

export default GrowthBookProvider;
