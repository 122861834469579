'use client';
// This component is responsible for rendering the Gladly chat widget on the website.
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { getRouteTitle, trackEvent } from 'utils/segment';

const PAGE_BLOCKLIST = ['/listings/start'];

// More info here https://developer.gladly.com/glad-app/
const script = `
        !function(c,n,r,t){
          if(!c[r]){
            var i,d,p=[];
            d="PROD"!==t&&t?"STAGING"===t?"https://cdn.gladly.qa/gladly/chat-sdk/widget.js":t:"https://cdn.gladly.com/chat-sdk/widget.js",
            c[r]={init:function(){
              i=arguments;
              var e={then:function(t){
                return p.push({type:"t",next:t}),e},
                catch:function(t){
                  return p.push({type:"c",next:t}),e}
              };
              return e
            }},
            c.__onHelpAppHostReady__=function(t){
              if(delete c.__onHelpAppHostReady__,
                (c[r]=t).loaderCdn=d,i)
                for(var e=t.init.apply(t,i),n=0;n<p.length;n++){
                  var a=p[n];
                  e="t"===a.type?e.then(a.next):e.catch(a.next)
                }
            },
            function(){
              try{
                var t=n.getElementsByTagName("script")[0],
                    e=n.createElement("script");
                e.async=!0,
                e.src=d+"?q="+(new Date).getTime(),
                t.parentNode.insertBefore(e,t)
              }catch(t){}
            }()
          }
        }(window,document,'Gladly','${
          process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'PROD' : 'STAGING'
        }');
      `;

export const GladlyChat = () => {
  const { data, status } = useSession();
  const pathname = usePathname() || '';
  const gladlyEnabled = useFeatureIsOn('gladly-chat');
  const sfChatEnabled = useFeatureIsOn('sf-chat');
  const isInvalidPage = PAGE_BLOCKLIST.includes(pathname);

  useEffect(() => {
    // Only show gladly chat to logged in users on authed routes
    // and only show if the newer chat feature isn't enabled
    if (
      status === 'authenticated' &&
      gladlyEnabled &&
      !sfChatEnabled &&
      !isInvalidPage
    ) {
      const configScript = document.createElement('script');
      configScript.id = 'gladly-config';
      configScript.innerHTML = `window.gladlyConfig = { appId: '${process.env.NEXT_PUBLIC_GLADLY_APP_ID}' };`;

      const mainScript = document.createElement('script');
      mainScript.id = 'gladly-script';
      mainScript.innerHTML = script;

      // Append scripts to head, order matters!
      document.head.appendChild(mainScript);
      document.head.appendChild(configScript);

      // Function to attach the click event to button
      const attachClickListener = () => {
        const button = document.querySelector<HTMLElement>(
          '[data-aid="gladlyChatChatButton"], ' +
            '#gladlyChat_container .minimizedChatButtonSelector.minimizedChatButton'
        );
        if (button && !button.dataset.clickListenerAttached) {
          // Add the new click event listener
          button.addEventListener('click', () => {
            trackEvent('Navigation Chat Clicked', {
              source: getRouteTitle(pathname),
            });
          });
          // Mark the button as having the click listener attached
          button.dataset.clickListenerAttached = 'true';
        }
      };

      // Gladly button is removed from dom when gladly is expanded so we need to observe changes
      // Use MutationObserver to detect changes in the gladly container and re-attach the listener
      const observer = new MutationObserver(() => {
        attachClickListener();
      });

      const scriptLoadedInterval = setInterval(() => {
        const gladlyContainer = document.getElementById('gladlyChat_container');
        if (
          window.Gladly &&
          typeof window.Gladly.getUser === 'function' &&
          gladlyContainer
        ) {
          // Overwriting the 3rd party css is hard so we do it here for more control
          gladlyContainer.style.setProperty('z-index', '31', 'important');

          // Script has officially loaded, remove interval and set up Gladly
          clearInterval(scriptLoadedInterval);

          // Start observing the container for added nodes (so that we can attach events)
          observer.observe(gladlyContainer, {
            childList: true,
            subtree: true,
            attributes: true,
          });

          // Set gladly user if it hasn't been done already
          if (!window.Gladly.getUser()) {
            window.Gladly.setUser({
              name: data.user.profile?.firstName ?? '',
              email: data.user.profile?.email ?? '',
            });
          }
        }
      }, 500);

      // Cleanup function to remove the scripts when the component unmounts or path changes
      return () => {
        document.head.removeChild(mainScript);
        document.head.removeChild(configScript);
        clearTimeout(scriptLoadedInterval);
        observer.disconnect();
      };
    }
  }, [pathname, status, data, gladlyEnabled, isInvalidPage, sfChatEnabled]);

  return <></>;
};
