'use client';

import { ReactNode } from 'react';

import {
  BaseButtonProps,
  BaseButton,
} from 'components/Buttons/shared/BaseButton';
import { LoadingIcon } from 'components/Icons';
import { cn } from 'utils/tailwind';

export type ButtonProps = BaseButtonProps & {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  loading?: boolean;
  highlight?: boolean;
};

export const Button = ({
  ref,
  leadingIcon,
  trailingIcon,
  children,
  className,
  loading: loadingProp,
  highlight,
  ...buttonProps
}: ButtonProps) => {
  const variant = buttonProps.variant;
  const hasIcon = Boolean(leadingIcon) || Boolean(trailingIcon);

  // Loading is treated as true only if button is loading and is not disabled
  const loading = loadingProp && !buttonProps.disabled;

  return (
    <BaseButton
      data-loading={loading}
      ref={ref}
      className={cn(
        'gap-2',
        {
          'py-3':
            variant === 'solid' ||
            variant === 'outline' ||
            variant === 'outline-new' ||
            variant === 'subtle',
          'px-5':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            (!hasIcon || (Boolean(leadingIcon) && Boolean(trailingIcon))),
          'pr-5 pl-4':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            Boolean(leadingIcon) &&
            !trailingIcon,
          'pr-4 pl-5':
            (variant === 'solid' ||
              variant === 'outline' ||
              variant === 'outline-new' ||
              variant === 'subtle') &&
            Boolean(trailingIcon) &&
            !leadingIcon,
          'bg-mountain-100': highlight,
        },
        className
      )}
      {...buttonProps}
    >
      {leadingIcon && (
        <span
          aria-hidden={true}
          // Hide icons when loading
          className={cn({ invisible: loading })}
        >
          {leadingIcon}
        </span>
      )}

      <span
        // Hide children when loading
        className={cn(
          { invisible: loading },
          'flex items-center justify-center'
        )}
      >
        {children}
      </span>

      {trailingIcon && (
        <span
          aria-hidden={true}
          // Hide icons when loading
          className={cn({ invisible: loading })}
        >
          {trailingIcon}
        </span>
      )}

      {/* Show loading spinner when loading */}
      {loading && (
        <span className="absolute">
          <LoadingIcon
            size={variant === 'subtle' || variant === 'text' ? 18 : 24}
            className={cn({
              'stroke-white': variant === 'solid',
              'stroke-mountain-500':
                variant === 'outline' || variant === 'text',
              'stroke-island-700': variant === 'outline-new',
              'stroke-mountain-700': variant === 'subtle',
            })}
          />
        </span>
      )}
    </BaseButton>
  );
};
