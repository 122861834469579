import { datadogRum } from '@datadog/browser-rum';
import { reactPlugin } from '@datadog/browser-rum-react';
import { useSession } from 'next-auth/react';
import { use, useEffect, useState } from 'react';

import { getIsImpersonating } from 'utils/auth';

import { MobileContext } from './contexts/MobileContext';

const HAS_CREDS =
  process.env.NEXT_PUBLIC_DATADOG_APP_ID &&
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

const DatadogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = use(MobileContext);
  const isEnabled = !isMobile && HAS_CREDS;

  const { data: sessionData, status } = useSession();
  const [loadedUser, setLoadedUser] = useState(!isEnabled);
  const [initError, setInitError] = useState<Error | null>(null);

  useEffect(() => {
    if (isEnabled && !datadogRum.getInitConfiguration()) {
      try {
        datadogRum.init({
          applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID!,
          clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
          site: 'datadoghq.com',
          service: 'owner-web',
          env: process.env.NEXT_PUBLIC_APP_ENV,
          version: process.env.NEXT_PUBLIC_APP_VERSION,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 10,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
          plugins: [reactPlugin()],
        });
      } catch (error) {
        console.error('Failed to initialize Datadog:', error);
        setInitError(error as Error);
      }
    }
  }, [isEnabled]);

  // set Growthbook attributes with user session data
  // required for feature flags to work
  useEffect(() => {
    if (status !== 'loading' && isEnabled) {
      const userAccountId = sessionData?.user.profile?.accountId;
      const userEmail = sessionData?.user?.profile?.email;
      const username = sessionData?.user?.profile?.username;
      const managementTier = sessionData?.user?.profile?.managementTier;
      const isImpersonator = getIsImpersonating(sessionData);

      try {
        if (userAccountId && userEmail) {
          datadogRum.setUser({
            id: userAccountId,
            name: username,
            email: userEmail,
            managementPlan: managementTier,
            username,
            isImpersonator,
          });
        }

        setLoadedUser(true);
      } catch (error) {
        console.error('Error setting Datadog attributes:', error);
      }
    }
  }, [sessionData, status, isEnabled]);

  // IMPORTANT: This provider should never return null after attempting to initialize datadog
  // this will stop the entire app from rendering. We always want to either:
  // 1. Render children immediately if Datadog is disabled
  // 2. Render children after Datadog initialization
  // 3. Render children if Datadog fails to initialize
  const shouldRender = !isEnabled || loadedUser || initError;
  return shouldRender ? <>{children}</> : null;
};

export default DatadogProvider;
