'use client';

import {
  ToggleGroupSingleProps,
  ToggleGroupMultipleProps,
  Root,
} from '@radix-ui/react-toggle-group';
import { createContext, useMemo } from 'react';

import { CheckIcon } from 'components/Icons';
import { BaseProps } from 'components/types';
import { cn } from 'utils/tailwind';

import type { ReactNode } from 'react';

export interface ButtonGroupContextValue {
  activeIcon: ReactNode;
  noIcon?: boolean;
}

export type ButtonGroupProps = (
  | Omit<ToggleGroupSingleProps, 'asChild'>
  | Omit<ToggleGroupMultipleProps, 'asChild'>
) &
  BaseProps & { activeIcon?: ReactNode; noIcon?: boolean };

export const ButtonGroupContext = createContext<ButtonGroupContextValue>({
  activeIcon: <CheckIcon />,
  noIcon: false,
});

export const ButtonGroup = ({
  activeIcon = <CheckIcon />,
  className,
  noIcon,
  invertTheme,
  ...props
}: ButtonGroupProps) => {
  const contextValue = useMemo(
    () => ({ activeIcon: noIcon ? null : activeIcon, noIcon }),
    [activeIcon, noIcon]
  );

  return (
    <ButtonGroupContext value={contextValue}>
      <Root
        {...props}
        className={cn(
          'flex flex-row',
          { 'theme-invert': invertTheme },
          className
        )}
      />
    </ButtonGroupContext>
  );
};
